import Headroom from "headroom.js"

const mainHeader = document.querySelector(".m-header")
const mainNav = document.querySelector(".m-nav")
const userButton = document.querySelector(".m-header .m-header-user")
const closers = document.querySelectorAll(".--js-main-nav-closer")
const notification = document.querySelector(".i-notification")

export const mobileQuery = window.matchMedia("(max-width: 74.999em)")
const desktopQuery = window.matchMedia("(min-width: 74.999em)")


const headRoomHeaderOptions: Headroom.HeadroomOptions = {
  offset: notification?.clientHeight ?? 0,
}

export const headRoomHeader = mainHeader ? new Headroom(mainHeader, headRoomHeaderOptions) : null

if (headRoomHeader) headRoomHeader.init()

  closers.forEach(closer => {
    closer?.addEventListener("click", (e) => {
      if (!mobileQuery.matches) return
      e.preventDefault()

      mainHeader?.classList.remove("--user-open")
      userButton?.classList.remove("--open")
    })
  })

  userButton?.addEventListener("click", () => {
    if (!mobileQuery.matches) return

    mainHeader?.classList.add("--user-open")
    mainNav?.classList.remove("--open")
    userButton?.classList.add("--open")
  })

  new ResizeObserver(() => {
    if (!desktopQuery.matches) return

    mainHeader?.classList.remove("--user-open")
    userButton?.classList.remove("--open")
  }).observe(document.body)
